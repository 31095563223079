import React, { useState, useEffect } from "react";
import StandardButton from '../components/StyledComponents/StandardButton'
import {
  useNavigate,
  useSearchParams,
  useOutletContext,
} from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)",
}));

function BrickAndMortarSignup() {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    degrees: "",
    city: "",
    state: "",
    zip_code: "",
    specialty: "",
    email: "",
    phone_number: "",
    license_number: "",
    license_state: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [touched, setTouched] = useState({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const admin_id = JSON.parse(localStorage.getItem("practiceAdminId"));
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const { user } = useAuth0();
  const { handleLogin } = useOutletContext();

  const specialties = [
    "General Practice",
    "Nutrition",
    "Internal Medicine",
    "Dermatology",
    "Cardiology",
    "Neurology",
    "Ophthalmology",
    "Orthopedic Surgery",
    "Pulmonology",
    "Radiology",
    "Vascular Surgery",
  ];
  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  };

  useEffect(() => {
    if (Object.values(touched).some((field) => field)) {
      setError("");
    }
  }, [formData, touched]);

  useEffect(() => {
    if (user) {
      const updates = {};
      if (user.email) updates.email = user.email;
      if (user.given_name) updates.first_name = user.given_name;
      if (user.family_name) updates.last_name = user.family_name;

      setFormData((prevData) => ({ ...prevData, ...updates }));
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setTouched({});

    const data = new FormData();
    Object.keys(formData).forEach((key) => data.append(key, formData[key]));
    data.append("is_vet", true);
    data.append("vet_id", null);
    data.append("owner_id", null);
    data.append("telehealth", "false");
    data.append("admin_id", admin_id);
    data.set("username", user?.email || Math.random().toString());
    data.set("password", "password123");

    try {
      const response = await fetch(`${apiUrl}/api/v1/signup`, {
        method: "POST",
        body: data,
        headers: {
          // No need for Authorization header in signup
        },
      });

      const { token } = await response.json();

      if (token) {
        localStorage.setItem("token", token); // Store the JWT.
        handleLogin();
        setTimeout(() => navigate("/appointments"), 1500);
        toast.success("Veterinarian registered successfully!");
      } else {
        setError("An error occurred during signup.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Signup error:", error);
    }
  };

  const renderStepContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            name="first_name"
            label="First Name"
            disabled={user?.given_name}
            value={user?.given_name || formData.first_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            name="last_name"
            label="Last Name"
            disabled={user?.family_name}
            value={user?.family_name || formData.last_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="degrees"
            label="Degrees"
            value={formData.degrees}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Specialty</InputLabel>
            <Select
              name="specialty"
              value={formData.specialty}
              onChange={handleChange}
            >
              {specialties.map((specialty) => (
                <MenuItem key={specialty} value={specialty}>
                  {specialty}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            required
            fullWidth
            name="license_number"
            label="License Number"
            value={formData.license_number}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel required>License State</InputLabel>
            <Select
              name="license_state"
              value={formData.license_state}
              onChange={handleChange}
            >
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="email"
            label="Email"
            type="email"
            disabled={user?.email}
            value={user?.email || formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="phone_number"
            label="Phone Number"
            value={formData.phone_number}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            name="city"
            label="City"
            value={formData.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel required>State</InputLabel>
            <Select name="state" value={formData.state} onChange={handleChange}>
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            required
            fullWidth
            name="zip_code"
            label="Zip Code"
            value={formData.zip_code}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Container component="main" maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" component="h1" gutterBottom>
          Veterinarian Signup
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 3, width: "100%" }}
        >
          {renderStepContent()}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <StandardButton
              text="Sign Up"
              handleClick={handleSubmit}
              disabled={
                !(
                  formData.first_name &&
                  formData.last_name &&
                  formData.license_number &&
                  formData.license_state &&
                  formData.phone_number &&
                  formData.city &&
                  formData.state &&
                  formData.zip_code &&
                  formData.email
                )
              }
            />
          </Box>
        </Box>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mt: 2 }}>
            {success}
          </Alert>
        )}
      </StyledPaper>
    </Container>
  );
}

export default BrickAndMortarSignup;
