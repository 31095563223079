import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import StyledPaper from "../components/StyledComponents/StyledPaper";
import StandardButton from "../components/StyledComponents/StandardButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ThemeProvider } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import StandardAccordion from "../components/StyledComponents/StandardAccordion";

const HospitalCodeInfo = {
  "What is a hospital code?":
    "A hospital code is a unique identifier provided to veterinary practices for use when signing up with AiLoVet. It helps verify your practice and ensures secure access to your organization's data.",
  "How do I create a hospital code?":
    "The successful registration of a hospital admin will generate a hospital code.",
  "Where can I find my hospital code?":
    "Each user can access their code from the Account Settings page upon registration. When signing up for the first time, obtain the hospital code from your practice’s admin, or create a practice admin account if none exists.",
};

function VetSignup({ theme }) {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const { loginWithRedirect } = useAuth0();
  const [hasHospitalCode, setHasHospitalCode] = useState("");
  const [practiceCode, setPracticeCode] = useState("");
  const [error, setError] = useState("");
  const handleRadioChange = (event) => {
    setHasHospitalCode(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/check-practice-code/${practiceCode}`
      );
      const result = await response.json();
      if (response.ok) {
        const admin_id = result.practice_admin.id
        localStorage.setItem("userTypeToSignup", "vet");
        localStorage.setItem("practiceAdminId", JSON.stringify(admin_id));
        toast.success("Practice found. Redirecting to signup...", {
          position: "bottom-center",
        });
        setTimeout(
          () =>
            loginWithRedirect({
              authorizationParams: { screen_hint: "signup" },
            }),
          2000
        );
      } else {
        setError(result.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <StyledPaper elevation={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 2,
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowBackIcon />}
              sx={{
                textTransform: "none",
                color: "black",
                "&:hover": {
                  color: "#3991da",
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => navigate(`/`)}
            >
              Back
            </Button>
            <Typography
              sx={{
                ml: "auto",
                fontSize: "0.9rem",
                color: "black",
              }}
            >
              Veterinarian Signup
            </Typography>
          </Box>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "1.25rem" }}
            gutterBottom
          >
            Getting Started with AiLoVet
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              mt: 4,
            }}
          >
            <FormControl>
              <FormLabel
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "black",
                  "&.Mui-focused": {
                    color: "black",
                  },
                }}
              >
                Do you have a hospital code?
              </FormLabel>
              <RadioGroup
                row
                value={hasHospitalCode}
                onChange={handleRadioChange}
                sx={{ mb: 3 }}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#0FBEB7",
                        },
                      }}
                    />
                  }
                  label={<Typography>Yes</Typography>}
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#0FBEB7",
                        },
                      }}
                    />
                  }
                  label={<Typography>No</Typography>}
                />
              </RadioGroup>
            </FormControl>
            {Object.entries(HospitalCodeInfo).map(([key, value], index) => (
              <StandardAccordion
                key={index}
                header={key}
                body={value}
                sx={{
                  width: "100%",
                  backgroundColor: "#fafafa",
                  "&:before": {
                    display: "none", // Removes the thin grey border
                  },
                }}
              />
            ))}
            {hasHospitalCode === "no" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Button
                    variant="text"
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      mb: 1,
                      textTransform: "none",
                      color: "black",
                      fontSize: "1rem",
                      "&:hover": {
                        color: "#0FBEB7",
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => navigate(`/admin-signup-check`)}
                  >
                    Go to Admin Signup
                  </Button>
                </Box>
              </>
            )}
            {hasHospitalCode === "yes" && (
              <>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: "black",
                    mt: 5,
                  }}
                >
                  Please enter your 5-digit hospital code
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <TextField
                    sx={{ mt: 1.5, mb: 1 }}
                    required
                    id="practiceCode"
                    label="Practice Code"
                    name="practiceCode"
                    autoFocus
                    value={practiceCode}
                    onChange={(e) => setPracticeCode(e.target.value)}
                  />
                  <StandardButton
                    text="Submit"
                    sx={{ width: "20%" }}
                    handleClick={handleSubmit}
                    disabled={practiceCode.length < 5}
                  />
                </Box>
              </>
            )}
          </Box>
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
}

export default VetSignup;
