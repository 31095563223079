import BrickAndMortarSignup from "./BrickAndMortarSignup"
import PracticeAdminSignup from "./PracticeAdminSignup"

function RenderSignupForm({ theme }) {
    const storedUserType = localStorage.getItem("userTypeToSignup");
    console.log("type of user signing up:", storedUserType)

    if (storedUserType === "admin") {
        return (
            <PracticeAdminSignup theme={theme} />
        )
    } else if (storedUserType === "vet") {
        return (
            <BrickAndMortarSignup />
        )
    } else {
        return <div>Please select a user type to sign up.</div>;
    }
}

export default RenderSignupForm