import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  Paper,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import StandardButton from "../components/StyledComponents/StandardButton";
import { useAuth0 } from '@auth0/auth0-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)",
}));

function Login({ theme }) {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { loginWithRedirect, user } = useAuth0();

  useEffect(() => {
    if (user) {
      console.log('User email:', user.email); // Send this to the backend.
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <StyledPaper elevation={3}>
          <Typography variant="h4" component="h1" gutterBottom>
            Login
          </Typography>
            <StandardButton text="Sign In" handleClick={() => loginWithRedirect()} />
          {error && (
            <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
              {success}
            </Alert>
          )}
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
}

export default Login;
