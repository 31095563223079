import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SummaryModal from "../components/SummaryModal";
import InputAdornment from "@mui/material/InputAdornment";
import StandardButton from "../components/StyledComponents/StandardButton";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ThemeProvider } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NoSleep from "nosleep.js";
import { jwtDecode } from "jwt-decode";
import useTokenRedirect from "../utils/authUtils";

function AptDetails({ theme }) {
  const params = useParams();
  const aptId = params.id;
  const navigate = useNavigate();
  const [apt, setApt] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isListening, setIsListening] = useState(false);
  const isListeningRef = useRef(false);
  const [isEditable, setIsEditable] = useState(null);
  const [open, setOpen] = useState(false);
  const [notesSaved, setNotesSaved] = useState(false);
  const recognitionRef = useRef(null);
  const isEditableRef = useRef(isEditable);
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const lastTranscriptRef = useRef("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getTokenOrRedirect } = useTokenRedirect();
  const token = getTokenOrRedirect()

  function getUserFromToken() {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken;
    } catch (error) {
      console.error("Invalid token:", error);
      return null;
    }
  }
  const userData = getUserFromToken();
  const userType = userData.sub.user_type;
  console.log(userType);

  const fetchAppointment = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/appointment/${aptId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();

        setApt(data);
        setIsLoading(false);
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error fetching appointment:", error);
      setIsLoading(false);
    }
  }, [aptId, navigate, apiUrl]);

  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  useEffect(() => {
    isEditableRef.current = isEditable;
  }, [isEditable]);

  const noSleep = new NoSleep();

  function enableNoSleep() {
    noSleep.enable();
    console.log("NoSleep enabled");
  }

  function disableNoSleep() {
    noSleep.disable();
    console.log("NoSleep disabled");
  }

  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = true;
      recognition.lang = "en-US";

      recognition.onresult = (event) => {
        let finalTranscript = "";
        let currentTranscript = "";

        Array.from(event.results).forEach((result) => {
          const transcript = result[0].transcript;

          if (result.isFinal) {
            currentTranscript = transcript;
            finalTranscript += transcript;
          }
        });

        if (finalTranscript && finalTranscript !== lastTranscriptRef.current) {
          lastTranscriptRef.current = finalTranscript;

          setApt((prevApt) => {
            return {
              ...prevApt,
              appointment_notes: prevApt.appointment_notes.map((note, index) =>
                index === isEditableRef.current
                  ? { ...note, notes: note.notes + " " + currentTranscript }
                  : note
              ),
            };
          });
        }
      };

      recognition.onend = () => {
        if (isListeningRef.current) {
          console.log("Speech recognition ended. Restarting...");
          recognition.start(); // Restart recognition automatically
        }
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error", event);
        // if (event.error === "no-speech" || event.error === "audio-capture") {
        //   recognition.start(); // Optionally restart on errors
        // }
      };

      recognitionRef.current = recognition;

      return () => {
        if (recognitionRef.current) {
          recognitionRef.current.stop();
          recognitionRef.current = null;
        }
      };
    } else {
      console.warn("Speech Recognition API is not supported in this browser.");
    }
  }, []);

  useEffect(() => {
    if (isListening) {
      isListeningRef.current = true;
      recognitionRef.current.start();
    } else {
      isListeningRef.current = false;
      recognitionRef.current.stop();
    }
  }, [isListening]);

  const toggleListening = () => {
    if (isListening) {
      setIsListening(false);
      disableNoSleep();
    } else {
      if (isEditable !== null) {
        setIsListening(true);
        enableNoSleep();
      }
    }
  };

  const handleUpdateNoteContent = (index, newContent) => {
    const updatedNotes = apt.appointment_notes.map((note, i) =>
      i === index ? { ...note, notes: newContent } : note
    );
    setApt((prev) => {
      return { ...prev, appointment_notes: updatedNotes };
    });
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSaveNotes = async (index, id, noteName, notes) => {
    if (isListening) toggleListening();
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/update-appointment-notes/${id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id, name: noteName, notes }),
          credentials: "include",
        }
      );

      if (response.ok) {
        handleClick();
        setNotesSaved(true);
        const updatedNotes = apt?.appointment_notes?.map((note, i) =>
          i === index ? { ...note, notes, name: noteName } : note
        );
        setApt((prevApt) => ({
          ...prevApt,
          appointment_notes: updatedNotes,
        }));
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error saving notes:", error);
    }
  };

  const addNewRecording = async () => {
    const newRecording = {
      name: "Untitled Recording",
      notes: "",
    };

    try {
      const response = await fetch(
        `${apiUrl}/api/v1/create-appointment-notes/${aptId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newRecording),
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();

        setApt((prevValue) => ({
          ...prevValue,
          appointment_notes: [
            ...prevValue.appointment_notes,
            { id: data.id, name: data.name, notes: data.notes },
          ],
        }));
      } else if (response.status === 401) {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        throw new Error("Failed to add new recording");
      }
    } catch (error) {
      console.error("Error adding new recording:", error);
    }
  };

  const handleDeleteRecording = async (noteId) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/delete-appointment-note/${noteId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const updatedNotes = apt.appointment_notes.filter(
          (note) => note.id !== noteId
        );
        setApt({ ...apt, appointment_notes: updatedNotes });
      } else {
        console.error("Failed to delete the note");
      }
    } catch (error) {
      console.error("Error deleting the note:", error);
    }
  };

  const handleChangeNoteNames = (index, newName) => {
    const updatedNotes = apt.appointment_notes.map((note, i) =>
      i === index ? { ...note, name: newName } : note
    );
    setApt((prev) => {
      return { ...prev, appointment_notes: updatedNotes };
    });
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  const datetime_utc = apt.datetime_utc
    ? new Date(apt.datetime_utc + "Z")
    : null;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: userTimeZone,
  };
  const localDateTime = datetime_utc
    ? datetime_utc.toLocaleString("en-US", options)
    : "N/A";
  const [date, time] = localDateTime.split(", ");

  const handleNavToDashboard = () => {
    navigate("/appointments");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          sx={{
            ml: 2,
            mt: 2,
            textTransform: "none",
            color: "black",
            "&:hover": {
              color: "#3991da",
              backgroundColor: "transparent",
            },
          }}
          onClick={handleNavToDashboard}
        >
          Back to Medical Dashboard
        </Button>

        <Grid container spacing={1} sx={{ width: "100%", height: "100%" }}>
          <Grid item xs={12} sm={12} md={7}>
            <Box
              sx={{
                boxShadow: "0 1px 3px rgba(0, 0, 0, 1)",
                borderRadius: "10px",
                mx: { xs: 1, sm: 2 },
                padding: { xs: 2, sm: 3 },
                backgroundColor: "#F9F9F9",
                maxHeight: "100%",
                maxWidth: "100%",
                boxSizing: "border-box",
                overflow: "auto",
              }}
              mx={4}
              mt={1}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  color: "black",
                  display: "inline-block",
                  textTransform: "capitalize",
                }}
              >
                Appointment Details
              </Typography>
              <Box>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    Appointment Date:
                  </strong>{" "}
                  {date || "N/A"}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    Appointment Time:{" "}
                  </strong>{" "}
                  {time || "N/A"}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    {" "}
                    Pet Name:{" "}
                  </strong>{" "}
                  {apt.pet?.name || "N/A"}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    {" "}
                    PIMS ID:{" "}
                  </strong>{" "}
                  {apt.pet?.pims_id || "N/A"}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    {" "}
                    Pet Age:{" "}
                  </strong>{" "}
                  {apt.pet?.age || "N/A"}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    {" "}
                    Pet Weight:{" "}
                  </strong>{" "}
                  {apt.pet?.weight || "N/A"}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    {" "}
                    Pet Sex:{" "}
                  </strong>{" "}
                  {apt.pet?.sex || "N/A"}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    {" "}
                    Pet Breed:{" "}
                  </strong>{" "}
                  {apt.pet?.breed || "N/A"}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    {" "}
                    Reason for Visit:{" "}
                  </strong>{" "}
                  {apt.reason || "N/A"}
                </Typography>
                <Typography variant="body1" mb={2}>
                  <strong
                    style={{ textShadow: "0px 1px 0px rgba(0, 0, 0, 0.2)" }}
                  >
                    {" "}
                    Primary Veterinarian:{" "}
                  </strong>{" "}
                  {`Dr. ${apt.veterinarian.first_name} ${apt.veterinarian.last_name}` ||
                    "N/A"}
                </Typography>

                {/* Recordings Section */}
                <Typography variant="h6" gutterBottom>
                  Recordings
                </Typography>
                {userType === "vet" ? (
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{
                      fontSize: "0.75rem",
                      color: "grey",
                      display: "block",
                    }}
                  >
                    Click on mic to stop and start recordings. Mic is green
                    while the recording is active.
                  </Typography>
                ) : (
                  ""
                )}
                {apt?.appointment_notes?.map((note, index) =>
                  userType === "vet" ? (
                    <Accordion key={index} expanded={isEditable === index} sx={{ mb: 1 }} >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        onClick={(e) => {
                          if (isListening) toggleListening();

                          isEditable === index
                            ? setIsEditable(null)
                            : setIsEditable(index);
                        }}
                      >
                        {isEditable === index ? (
                          <TextField
                            value={note.name || ""}
                            onChange={(e) =>
                              handleChangeNoteNames(index, e.target.value)
                            }
                            onClick={(e) => e.stopPropagation()}
                            variant="standard"
                            sx={{ flexGrow: 1 }}
                          />
                        ) : (
                          <Typography>{note.name}</Typography>
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        {isEditable === index ? (
                          <Box>
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              value={note.notes}
                              onChange={(e) => {
                                handleUpdateNoteContent(index, e.target.value);
                              }}
                              variant="outlined"
                              sx={{
                                maxWidth: "100%",
                                boxSizing: "border-box",
                                overflow: "auto",
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: isMobile
                                          ? "column"
                                          : "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <IconButton
                                        onClick={toggleListening}
                                        sx={{
                                          color: isListening
                                            ? "green"
                                            : "default",
                                          backgroundColor: isListening
                                            ? "#c1f5b8"
                                            : "transparent",
                                          "&:hover": {
                                            backgroundColor: isListening
                                              ? "#6dfa57"
                                              : "rgba(0, 0, 0, 0.04)",
                                          },
                                          fontSize: isMobile
                                            ? "1.25rem"
                                            : "1.5rem",
                                          marginBottom: isMobile ? "8px" : "0",
                                        }}
                                      >
                                        <MicIcon />
                                      </IconButton>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          borderRadius: "25px",
                                          marginLeft: isMobile ? "0" : "5px",
                                          backgroundColor: "#0FBEB7",
                                          textTransform: "capitalize",
                                          "&:hover": {
                                            backgroundColor: "#FFFFFF",
                                            color: "#757575",
                                          },
                                          fontSize: isMobile
                                            ? "0.75rem"
                                            : "1rem",
                                          padding: isMobile
                                            ? "4px 8px"
                                            : "8px 16px",
                                          marginBottom: isMobile ? "8px" : "0",
                                        }}
                                        onClick={() => {
                                          handleSaveNotes(
                                            index,
                                            note.id,
                                            note.name,
                                            note.notes
                                          );
                                          console.log(
                                            "Saving note:",
                                            note.id,
                                            note.name,
                                            note.notes
                                          );
                                        }}
                                      >
                                        Save
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="error"
                                        sx={{
                                          marginLeft: isMobile ? "0" : "10px",
                                          borderRadius: "25px",
                                          textTransform: "capitalize",
                                          fontSize: isMobile
                                            ? "0.75rem"
                                            : "1rem",
                                          padding: isMobile
                                            ? "4px 8px"
                                            : "8px 16px",
                                        }}
                                        onClick={() =>
                                          handleDeleteRecording(note.id)
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </Box>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        ) : (
                          <Typography>
                            {note.notes || "Click to edit this recording..."}
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <Accordion key={index} expanded={isEditable === index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        onClick={() => {
                          isEditable === index
                            ? setIsEditable(null)
                            : setIsEditable(index);
                        }}
                      >
                        <Typography>{note.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>{note.notes}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  )
                )}

                {userType === "vet" ? (
                  <>
                    <StandardButton text="+ Add New Recording" handleClick={addNewRecording}/>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        Notes Saved Successfully
                      </Alert>
                    </Snackbar>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Box
              sx={{
                boxShadow: "0 1px 3px rgba(0, 0, 0, 1)",
                borderRadius: "10px",
                mx: { xs: 1, sm: 2 },
                padding: { xs: 2, sm: 3 },
                backgroundColor: "#F9F9F9",
                maxHeight: "100%",
                maxWidth: "100%",
                boxSizing: "border-box",
                overflow: "auto",
              }}
              mx={4}
              mt={1}
            >
              <SummaryModal
                theme={theme}
                notesSaved={apt?.appointment_notes?.length}
                aptId={aptId}
                userType={userType}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default AptDetails;
