import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StyledPaper from "../components/StyledComponents/StyledPaper";
import StandardButton from "../components/StyledComponents/StandardButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ThemeProvider } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

function PracticeAdminSignup({ theme }) {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    practice_name: "",
    practice_email: "",
    practice_phone: "",
    practice_city: "",
    practice_state: "",
    practice_zip_code: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [touched, setTouched] = useState({});
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const { user } = useAuth0();
  const { handleLogin } = useOutletContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setTouched((prevTouched) => ({ ...prevTouched, [name]: true }));
  };

  useEffect(() => {
    if (Object.values(touched).some((field) => field)) {
      setError("");
    }
  }, [formData, touched]);

  useEffect(() => {
    if (user?.email)
      setFormData((prevData) => ({ ...prevData, practice_email: user.email }));
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setTouched({});

    try {
      const response = await fetch(`${apiUrl}/api/v1/practice-admin-signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          username: user?.email || Math.random().toString(),
          password: "password123",
        }),
      });

      const { token } = await response.json();

      if (token) {
        localStorage.setItem("token", token); // Store the JWT.
        handleLogin();
        setTimeout(() => navigate("/appointments"), 1500);
        toast.success(
          "Practice admin account created successfully! Your unique practice code has been sent to: " +
            user?.email
        );
      } else {
        setError("An error occurred during signup.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Signup error:", error);
    }
  };
  console.log(user)
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <StyledPaper elevation={3}>
          {/* Back to Login Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mb: 2,
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowBackIcon />}
              sx={{
                textTransform: "none",
                color: "black",
                "&:hover": {
                  color: "#3991da",
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => navigate(`/practice-code-check`)}
            >
              Back
            </Button>
          </Box>
          {!success ? (
            <>
              <Typography variant="h4" component="h1" gutterBottom>
                Practice Admin Signup
              </Typography>

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{
                  mt: 3,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="practice_name"
                      label="Practice Name"
                      value={formData.practice_name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      name="practice_email"
                      label="Practice Email"
                      value={formData.practice_email}
                      disabled={user?.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      name="practice_phone"
                      label="Practice Phone Number"
                      value={formData.practice_phone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          name="practice_city"
                          label="Practice City"
                          value={formData.practice_city}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                          <InputLabel>Practice State</InputLabel>
                          <Select
                            name="practice_state"
                            value={formData.practice_state}
                            onChange={handleChange}
                          >
                            {states.map((state) => (
                              <MenuItem key={state} value={state}>
                                {state}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          required
                          fullWidth
                          name="practice_zip_code"
                          label="Practice Zip Code"
                          value={formData.practice_zip_code}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <StandardButton
                      type="submit"
                      text="Sign Up"
                      disabled={!(formData.practice_name && formData.practice_email && formData.practice_phone && formData.practice_zip_code)}
                    >
                      Sign Up
                    </StandardButton>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <Typography
              variant="body"
              component="body"
              gutterBottom
              sx={{ backgroundColor: "white" }}
            >
              {success}
            </Typography>
          )}

          {/* Error or Success Alerts */}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
}

export default PracticeAdminSignup;
