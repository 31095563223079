import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import StandardButton from "../components/StyledComponents/StandardButton";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)",
}));

function PracticeCodeCheck({ theme }) {
  const [practiceCode, setPracticeCode] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/check-practice-code/${practiceCode}`
      );
      const result = await response.json();
      if (response.ok) {
        const admin_id = result.practice_admin.id;
        setSuccess("Practice found. Redirecting to signup...");
        setTimeout(
          () => navigate(`/brick-and-mortar-signup?admin_id=${admin_id}`),
          2000
        );
      } else {
        setError(result.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <StyledPaper elevation={3}>
          <Typography variant="h5" component="h1" gutterBottom align="center">
            Please enter your practice's unique code
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="practiceCode"
              label="Practice Code"
              name="practiceCode"
              autoFocus
              value={practiceCode}
              onChange={(e) => setPracticeCode(e.target.value)}
            />
            <StandardButton text="Submit" handleClick={handleSubmit} disabled={practiceCode.length < 5} />
            <Button
              fullWidth
              variant="text"
              onClick={() => navigate("/practice-admin-signup")}
              sx={{
                mt: 1,
                color: "#757575",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                  color: "black",
                },
              }}
            >
              No Code? <br /> Create an admin account
            </Button>
          </Box>
          {error && (
            <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
              {success}
            </Alert>
          )}
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
}

export default PracticeCodeCheck;
