import React, { useState, useEffect } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { Container, Box, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

function Authentication({ theme }) {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const { user, error } = useAuth0();
  const jwt = localStorage.getItem("token");
  const { handleLogin } = useOutletContext();

  useEffect(() => {
    if (user) {
      console.log("User email:", user.email); // Send this to the backend.
      sendEmailToBackend(user.email);
    } else if (error) {
      toast.error("Authentication Failed. Please try again.")
      navigate('/')
    }
  }, [user, error]);

  const sendEmailToBackend = async (email) => {
    const response = await fetch(`${apiUrl}/api/v1/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const { token } = await response.json();

    if (token) {
      localStorage.setItem("token", token); // Store the JWT.
      handleLogin();
      setTimeout(() => navigate("/appointments"), 1500);
    } else {
      navigate('/render-signup-forms')
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            height: "100vh", // Full viewport height
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center vertically
            alignItems: "center", // Center horizontally
          }}
        >
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>Logging in...</Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Authentication;
