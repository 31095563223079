import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Modal,
} from "@mui/material";
import StyledPaper from "../components/StyledComponents/StyledPaper";
import StandardButton from "../components/StyledComponents/StandardButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ThemeProvider } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import StandardAccordion from "../components/StyledComponents/StandardAccordion";

const AdminAccountFaqs = {
  "What is an admin account?":
    "The admin account serves as the central hub for managing your practice. It connects all your doctors and staff, allowing them to access and collaborate on all patient cases seamlessly. <br /><br /><strong>Please note: </strong> Admins can view all appointment cases for their practice but cannot create cases themselves.",
  "Why is it important to remember your admin account login?":
    "The admin account login credentials are crucial for maintaining your practice's operations. You will need them to manage user access, including removing doctors or employees who leave your practice.",
  "Where can I find my hospital code?":
    "Once your admin account is created, your hospital code will be sent to the practice email address used during registration. You can also find the code on the Account page after signing up.",
};

function AdminSignupCheck({ theme }) {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const { loginWithRedirect } = useAuth0();
  const [isPracticeAdmin, setIsPracticeAdmin] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleRadioChange = (event) => {
    setIsPracticeAdmin(event.target.value);
  };

  const handleNext = () => {
    if (isPracticeAdmin === "no") {
      navigate("/vet-signup");
    } else if (isPracticeAdmin === "yes") {
      setOpenModal(true);
    }
  };

  const handleContinueToSignup = () => {
    localStorage.setItem("userTypeToSignup", "admin");
    loginWithRedirect({
      authorizationParams: { screen_hint: "signup" },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <StyledPaper elevation={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 2,
            }}
          >
            <Button
              variant="text"
              startIcon={<ArrowBackIcon />}
              sx={{
                textTransform: "none",
                color: "black",
                "&:hover": {
                  color: "#3991da",
                  backgroundColor: "transparent",
                },
              }}
              onClick={() => navigate(`/`)}
            >
              Back
            </Button>
            <Typography
              sx={{
                ml: "auto",
                fontSize: "0.9rem",
                color: "black",
              }}
            >
              Admin Signup
            </Typography>
          </Box>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "1.25rem" }}
            gutterBottom
          >
            Getting Started with AiLoVet
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              mt: 4,
            }}
          >
            <FormControl>
              <FormLabel
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "black",
                  "&.Mui-focused": {
                    color: "black",
                  },
                }}
              >
                Please confirm that you wish to register as the practice admin:
              </FormLabel>
              <RadioGroup
                row
                value={isPracticeAdmin}
                onChange={handleRadioChange}
                sx={{ mb: 3 }}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#0FBEB7",
                        },
                      }}
                    />
                  }
                  label={<Typography>Yes</Typography>}
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#0FBEB7",
                        },
                      }}
                    />
                  }
                  label={
                    <Typography>
                      No, I wish to register as a veterinarian
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
            {Object.entries(AdminAccountFaqs).map(([key, value], index) => (
              <StandardAccordion
                key={index}
                header={key}
                body={<span dangerouslySetInnerHTML={{ __html: value }} />}
                sx={{
                  width: "100%",
                  backgroundColor: "#fafafa",
                  "&:before": {
                    display: "none", // Removes the thin grey border
                  },
                }}
              />
            ))}
            {isPracticeAdmin === "no" && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mt: 3,
                  }}
                >
                  <Button
                    variant="text"
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      mb: 1,
                      textTransform: "none",
                      color: "black",
                      fontSize: "1rem",
                      "&:hover": {
                        color: "#0FBEB7",
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => navigate(`/vet-signup`)}
                  >
                    Go to Veterinarian Signup
                  </Button>
                </Box>
              </>
            )}

            {isPracticeAdmin === "yes" && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                <StandardButton
                  text="Next"
                  sx={{ width: "10%" }}
                  handleClick={handleNext}
                />
              </Box>
            )}
            <Modal open={openModal} onClose={handleCloseModal}>
              <Box
                sx={{
                  position: "absolute",
                  top: "30%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 600,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Important Reminder
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                  Please use a practice email, not an employee email, for
                  registration as a practice admin.
                </Typography>
                <Button
                  variant="text"
                  endIcon={<ArrowForwardIcon />}
                  onClick={handleContinueToSignup}
                  sx={{
                    mb: 1,
                    textTransform: "none",
                    color: "black",
                    "&:hover": {
                      color: "#0FBEB7",
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Continue to Signup
                </Button>
              </Box>
            </Modal>
          </Box>
        </StyledPaper>
      </Container>
    </ThemeProvider>
  );
}

export default AdminSignupCheck;
