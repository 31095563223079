export const fetchVetDataApi = async (apiUrl, adminId, token) => {
    const response = await fetch(
      `${apiUrl}/api/v1/vet-by-admin-id/${adminId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    return response;
  };

  export const fetchPatientDataApi = async (apiUrl, adminId, token) => {
    const response = await fetch(
      `${apiUrl}/api/v1/patient-by-admin-id/${adminId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    return response;
  };