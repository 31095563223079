import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StandardAccordion = ({ header, body, sx = {} }) => {
  return (
    <Accordion sx={{ ...sx }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="accordion-content"
        id="accordion-header"
      >
        <Typography sx={{ fontWeight: "medium" }}>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{body}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default StandardAccordion;
