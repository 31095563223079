import React from "react";
import { Box, Typography, Divider } from "@mui/material";

export default function AdminDataGrid({
  alignment,
  employeeColumns,
  patientColumns,
  employeeRows,
  patientRows,
  hospitalCode
}) {
  const columns = alignment === "employees" ? employeeColumns : patientColumns;
  const rows = alignment === "employees" ? employeeRows : patientRows;
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#feffff",
          borderRadius: "8px",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
          mt: 2,
          paddingBottom: 2
        }}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{ marginBottom: 2, textAlign: "center", paddingTop: 2 }}
        >
          {alignment === "employees"
            ? "Employee Information"
            : "Patient Information"}
        </Typography>
        <Box
          sx={{
            display: "flex", // Enables flexbox
            justifyContent: "center", // Aligns table horizontally
            alignItems: "center", // Aligns table vertically
          }}
        >
          <table
            style={{
              width: "95%",
              borderCollapse: "separate",
              borderSpacing: "0 5px", // Adds space between rows
              fontFamily: "Sora",
            }}
          >
            {/* Table Header */}
            <thead>
              <tr>
                {columns.map((col) => (
                  <th
                    key={col.field}
                    style={{
                      padding: "12px",
                      backgroundColor: "#f5f5f5",
                      color: "#333",
                      fontWeight: "bold",
                      textAlign: "left",
                      borderRadius: "8px 8px 0 0", // Rounded top corners
                      fontFamily: "Sora",
                    }}
                  >
                    {col.headerName}
                  </th>
                ))}
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {rows?.map((row, rowIndex) => (
                <tr
                  key={row.id}
                  style={{
                    backgroundColor: rowIndex % 2 !== 0 ? "#fafafa" : "#fff", // Zebra stripes
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                  }}
                >
                  {columns?.map((col) => (
                    <td
                      key={col.field}
                      style={{
                        fontFamily: "Sora",
                        padding: "12px",
                        textAlign: "left",
                        borderBottom: "none",
                        borderRadius:
                          rowIndex === rows.length - 1 ? "0 0 8px 8px" : "0",
                      }}
                    >
                      {row[col.field]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          
        </Box>
        <Box sx={{ textAlign: "left", mt: 2, ml: "2.5%" }}>
        <Typography>Hospital Code: {hospitalCode}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
