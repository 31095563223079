import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  CircularProgress,
  Alert,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import StandardButton from "../components/StyledComponents/StandardButton";

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "15px",
  background: "linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
}));

const Message = ({ message }) => (
  <section>
    <Typography variant="body1" sx={{ fontStyle: "italic", margin: 2 }}>
      {message}
    </Typography>
  </section>
);

function AccountSettings({ theme }) {
  const [searchParams, _setSearchParams] = useSearchParams();
  const [user, setUser] = useState(null);
  const [veterinarian, setVeterinarian] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const [message, setMessage] = useState("");
  const [newPracticeCode, setNewPracticeCode] = useState("");
  const [premium, setPremium] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (searchParams.get("paymentSuccess")) {
      handleUpgrade();
      navigate("/account-settings");
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/check_session`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        if (userData.vet_id) {
          fetchVeterinarianData(userData.vet_id);
        } else {
          setLoading(false);
        }
      } else {
        console.error("Failed to fetch user data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const fetchVeterinarianData = async (vetId) => {
    try {
      const response = await fetch(`${apiUrl}/vets/${vetId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const vetData = await response.json();
        console.log(vetData);
        setVeterinarian(vetData);
      } else {
        console.error("Failed to fetch veterinarian data");
      }
    } catch (error) {
      console.error("Error fetching veterinarian data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpgrade = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/upgrade-subscription`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setPremium(!premium);
        const result = await response.json();
        setVeterinarian((prevVet) => ({
          ...prevVet,
          subscription_status: result.subscription_status,
        }));
        setSuccess("Subscription upgraded successfully!");
        setError("");
      } else {
        throw new Error("Failed to upgrade subscription");
      }
    } catch (error) {
      console.error("Error upgrading subscription:", error);
      setError("Failed to upgrade subscription. Please try again.");
      setSuccess("");
    }
  };

  const handleCancel = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/cancel-subscription`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setPremium(!premium);
        const result = await response.json();
        setVeterinarian((prevVet) => ({
          ...prevVet,
          subscription_status: result.subscription_status,
        }));
        setSuccess("Subscription cancelled successfully!");
        setError("");
      } else {
        throw new Error("Failed to cancel subscription");
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      setError("Failed to cancel subscription. Please try again.");
      setSuccess("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json(); // Parse the JSON response

        // Redirect to the Stripe checkout URL
        window.location.href = data.checkout_url;
      } else {
        console.error(
          "Failed to create checkout session:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error during checkout session creation:", error);
    }
  };

  const handlePracticeCodeSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    const data = {
      practice_code: newPracticeCode,
    };

    try {
      const response = await fetch(
        `${apiUrl}/api/v1/update-practice-code/${veterinarian?.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      if (response.ok) {
        setSuccess("Clinic Registration Successfully Updated");
        fetchVeterinarianData(user.vet_id);
      } else {
        setError(
          result.error ||
            "An error occurred. Please check your registration code."
        );
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  const handleChange = (e) => {
    setNewPracticeCode(e.target.value);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh-79px)"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user || !user.vet_id) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh-79px)"
        pt={4}
      >
        <Typography>
          You must be logged in as a veterinarian to view this page.
        </Typography>
      </Box>
    );
  }

  const ProductDisplay = () => (
    <>
      <Box className="description" sx={{ textAlign: "center", paddingTop: 5 }}>
        <Typography variant="h5">AiLoVet Premium Subscription</Typography>
        <Typography variant="body1">$125 / month</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
        <form
          action="/create-checkout-session"
          method="POST"
          onSubmit={handleSubmit}
        >
          {veterinarian?.subscription_status !== "active" ? (
            <StandardButton
              text="Upgrade to Premium"
              handleClick={handleSubmit}
            />
          ) : (
            <>
              <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                <StandardButton
                  text="Cancel Subscription"
                  handleClick={handleCancel}
                />
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                  Subscription will be cancelled within 24h
                </Typography>
              </Box>
            </>
          )}
        </form>
      </Box>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: "#bde9fb",
          minHeight: "calc(100vh - 79px)",
          display: "flex",
          justifyContent: "center",
          paddingTop: 2,
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            {/* Error/Success Alerts */}
            {error && (
              <Grid item xs={12}>
                <Alert severity="error" sx={{ width: "100%" }}>
                  {error}
                </Alert>
              </Grid>
            )}
            {success && (
              <Grid item xs={12}>
                <Alert severity="success" sx={{ width: "100%" }}>
                  {success}
                </Alert>
              </Grid>
            )}
            {/* Account Settings Paper */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                mb: { xs: 3.5, md: 0 },
              }}
            >
              <StyledPaper elevation={3} sx={{ padding: 2, height: "100%" }}>
                <Grid container spacing={1}>
                  {/* Account Settings Header */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: 1,
                      }}
                    >
                      Account Settings
                    </Typography>
                  </Grid>

                  {/* User Name */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        marginTop: 1,
                      }}
                    >
                      Welcome, Dr.{" "}
                      {veterinarian?.first_name +
                        " " +
                        veterinarian?.last_name || "Veterinarian"}
                    </Typography>
                  </Grid>

                  {/* Subscription Status */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", textAlign: "center", mt: 2 }}
                    >
                      Subscription Status:{" "}
                      {veterinarian?.subscription_status || "Free"}
                    </Typography>
                    {message ? (
                      <Message message={message} />
                    ) : (
                      <ProductDisplay />
                    )}
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>

            {/* Clinic Information Paper */}
            <Grid item xs={12} md={6}>
              <StyledPaper elevation={3} sx={{ padding: 2, height: "100%" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                  Clinic Information
                </Typography>
                <Typography variant="h7" sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                  Hospital Code: {veterinarian?.practice_admin?.practice_code}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                  Practice Name:{" "}
                  {veterinarian?.practice_admin?.practice_name ||
                    "Not Available"}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                  Practice Email:{" "}
                  {veterinarian?.practice_admin?.practice_email ||
                    "Not Available"}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                  Practice Phone:{" "}
                  {veterinarian?.practice_admin?.practice_phone ||
                    "Not Available"}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                  City:{" "}
                  {veterinarian?.practice_admin?.practice_city ||
                    "Not Available"}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                  State:{" "}
                  {veterinarian?.practice_admin?.practice_state ||
                    "Not Available"}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
                  Update Clinic Registration:
                </Typography>
                <Box
                  component="form"
                  onSubmit={handlePracticeCodeSubmit}
                  noValidate
                  sx={{ mt: 1, width: "100%" }}
                >
                  <Box display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    margin="normal"
                    required
                    id="practice_code"
                    label="Practice Code"
                    name="practice_code"
                    autoComplete="practice code"
                    autoFocus
                    value={newPracticeCode}
                    onChange={handleChange}
                    sx={{ mr: 2 }}
                  />
                  <StandardButton text="submit" handleClick={handlePracticeCodeSubmit} />
                  </Box>
                  </Box>
              </StyledPaper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default AccountSettings;
