import React from "react";
import { Box, Typography, Divider, Stack } from "@mui/material";

export default function DataOverview({
  employeeDataPoints,
  patientDataPoints,
  alignment,
}) {
  const dataPoints =
    alignment === "employees" ? employeeDataPoints : patientDataPoints;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 2,
        backgroundColor: "#feffff",
        borderRadius: "8px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
      }}
    >
      {dataPoints.map((data, index) => (
        <React.Fragment key={index}>
          <Box sx={{ textAlign: "center", flex: 1, borderLeft: index ? 1 : 0 }}>
            <Typography variant="h6" fontWeight="bold">
              {data.value}
            </Typography>
            <Typography variant="body2">{data.label}</Typography>
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
}
