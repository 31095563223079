import * as React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StyledOverFlowMenu from "./StyledOverFlowMenu";


export default function OverFlowMenuButton({ isEditing, setIsEditing, handleDeleteAppointment }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsEditing(!isEditing)
  };

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button
        sx={{
          mb: 1,
          mt: 1,
          borderRadius: "25px",
          textTransform: "capitalize",
          backgroundColor: "white",
          color: "black",
          border: "1px solid black",
          "&:hover": {
            backgroundColor: "lightgrey",
          },
        }}
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      <StyledOverFlowMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          { isEditing ? "Exit Editing Mode" : "Edit" }
        </MenuItem>
        <MenuItem onClick={() => setOpenDeleteModal(true)} disableRipple>
          <DeleteIcon />
          Delete
        </MenuItem>
      </StyledOverFlowMenu>


      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Deleting an appointment is irreversible and deletes all related data. Would you like to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteModal(false)} color="secondary">
            No
          </Button>
          <Button
            onClick={() => {
              setOpenDeleteModal(false);
              handleDeleteAppointment();
            }}
            color="secondary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
