import React from "react";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";

const StyledPaperBase = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "linear-gradient(135deg, #ffffff 0%, #f0f4f8 100%)",
}));

const StyledPaper = ({ children, ...props }) => {
  return <StyledPaperBase {...props}>{children}</StyledPaperBase>;
};

export default StyledPaper;
